import React, { useEffect } from "react";
import styled from "styled-components";
import CubeLayer from "../components/cubeLayer";
import { TweenMax, Power4 } from "gsap";
import "../components/tlwrapper.css";
import { graphql } from "gatsby";

import SEO from "src/components/seo.js";

const TitleContainer = styled.div`
  display: flex;
  height: 100vh;
  position: absolute;
  align-items: center;
`;

const PageName = styled.h1`
  writing-mode: vertical-rl;
  -webkit-text-stroke: 1px white;
  color: transparent;
  font-family: "URWAccidalia", sans-serif;
  font-size: 42pt;
  padding-left: 16px;
  z-index: 1000;
  opacity: 0;

  @media (max-width: 1150px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0;
  padding: 0;
`;

const ProgramsPage = ({ data }) => {
  useEffect(() => {
    TweenMax.set("#SectionTitle", { y: -200, autoAlpha: 0 });
    TweenMax.to("#SectionTitle", 1, {
      y: 0,
      autoAlpha: 1,
      ease: Power4.easeInOut,
    });
    if (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    ) {
      const preventRefresh = e => {
        e.preventDefault();
      };

      window.addEventListener("touchmove", preventRefresh, { passive: false });
      return () => {
        window.removeEventListener("touchmove", preventRefresh, {
          passive: false,
        });
      };
    }
  }, []);
  return (
    <>
      <SEO title="HXOUSE · Programs" />
      <TitleContainer>
        <PageName id="SectionTitle"> PROGRAMS </PageName>
      </TitleContainer>
      <Wrapper id="programsWrapper">
        <CubeLayer data={data} />
      </Wrapper>
    </>
  );
};

export default ProgramsPage;

export const query = graphql`
  query {
    galleryconnections: allFile(
      filter: {
        relativePath: { regex: "/programs/gallery-connections/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 300
              maxHeight: 300
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
    galleryconversations: allFile(
      filter: {
        relativePath: { regex: "/programs/gallery-conversations/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 300
              maxHeight: 300
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
    gallerychallenges: allFile(
      filter: {
        relativePath: { regex: "/programs/gallery-challenges/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 300
              maxHeight: 300
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    gallerynomoredreams: allFile(
      filter: {
        relativePath: { regex: "/programs/gallery-nomoredreams/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 300
              maxHeight: 300
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }

          relativePath
        }
      }
    }

    galleryworkshops: allFile(
      filter: {
        relativePath: { regex: "/programs/gallery-workshops/" }
        extension: { regex: "/png|jpg|jpeg|gif/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          small: childImageSharp {
            fluid(
              maxWidth: 300
              maxHeight: 300
              cropFocus: CENTER
              fit: COVER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          large: childImageSharp {
            fluid(
              maxWidth: 500
              maxHeight: 500
              cropFocus: CENTER
              fit: COVER
              quality: 70
            ) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }

    connections: file(relativePath: { eq: "programs/connections.jpg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    conversations: file(relativePath: { eq: "programs/conversations.jpg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    challenges: file(relativePath: { eq: "programs/challenges.jpg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nomoredreams: file(relativePath: { eq: "programs/nomoredreams.jpg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    panels: file(relativePath: { eq: "programs/panels.jpg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    workshops: file(relativePath: { eq: "programs/workshops.jpg" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
