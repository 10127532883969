import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { TweenMax } from "gsap/TweenMax";

//change width, height and margins based on size of cube and whether it is large or not
const SmallContainer = styled.div`
  transform-style: preserve-3d;
  margin: auto;
`;

const CubeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
`;

//change size and background of faces based on size and whether it is large or not
const CubeFace1 = styled.div`
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.5s ease, transform 0.5s ease, border 0.5s ease;
  transform: ${props => `translateZ(${props.size / 2}px)`};
  background: ${props =>
    props.small
      ? props.color === 0
        ? `#000`
        : `#fff`
      : `url(${props.programs[0].image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: solid 1px #fff;
  outline: 1px solid transparent;
  position: absolute;
  backface-visibility: initial;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
`;

const CubeFace2 = styled.div`
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.5s ease, transform 0.5s ease, border 0.5s ease;
  transform: ${props =>
    `rotateX(180deg) rotateZ(180deg) translateZ(${props.size / 2}px)`};
  background: ${props =>
    props.small
      ? props.color === 0
        ? `#000`
        : `#fff`
      : `url(${props.programs[1].image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: solid 1px #fff;
  outline: 1px solid transparent;
  position: absolute;
  backface-visibility: initial;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
`;

const CubeFace3 = styled.div`
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.5s ease, transform 0.5s ease, border 0.5s ease;
  transform: ${props => `rotateY(-90deg) translateZ(${props.size / 2}px)`};
  background: ${props =>
    props.small
      ? props.color === 0
        ? `#000`
        : `#fff`
      : `url(${props.programs[2].image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: solid 1px #fff;
  outline: 1px solid transparent;
  position: absolute;
  backface-visibility: initial;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
`;

const CubeFace4 = styled.div`
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.5s ease, transform 0.5s ease, border 0.5s ease;
  transform: ${props => `rotateY(90deg) translateZ(${props.size / 2}px)`};
  background: ${props =>
    props.small
      ? props.color === 0
        ? `#000`
        : `#fff`
      : `url(${props.programs[3].image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: solid 1px #fff;
  outline: 1px solid transparent;
  position: absolute;
  backface-visibility: initial;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
`;

const CubeFace5 = styled.div`
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.5s ease, transform 0.5s ease, border 0.5s ease;
  transform: ${props => `rotateX(90deg) translateZ(${props.size / 2}px)`};
  background: ${props =>
    props.small
      ? props.color === 0
        ? `#000`
        : `#fff`
      : `url(${props.programs[3].image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: solid 1px #fff;
  outline: 1px solid transparent;
  position: absolute;
  backface-visibility: initial;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0);
`;

const Mask = styled.div`
  transform-style: preserve-3d;
  transition: opacity 0.5s ease, width 0.5s ease, height 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  opacity: 0.7;
  background-color: #000;
  width: 99%;
  height: 99%;
  &:hover {
    opacity: ${props => (props.small ? `0.7` : props.top ? `0.7` : `1`)};
  }
`;

//change color based on hover
const ProgramName = styled.h4`
  transform-style: preserve-3d;
  transition: color 0.25s ease, width 0.5s ease;
  text-align: center;
  width: 90%;
  color: ${props => (props.color === 0 ? `#fff` : `#fff`)};
  font-family: "URWAccidalia";
  font-size: 22px;
  z-index: 300;
  pointer-events: none;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

//Cube Component
const Cube = ({
  size,
  tiny,
  mobile,
  isTouch,
  small,
  position,
  rotAngle,
  programs,
  changeBackgroundImg,
  changeBackgroundCol,
  programType,
}) => {
  //State used to change color on hover
  const [faceColor, setFaceColor] = useState(0);

  //Functions to change color on hover
  const hoverInvert = () => {
    setFaceColor(1);
  };
  const hoverInvertOpposite = () => {
    setFaceColor(0);
  };

  useEffect(() => {
    if (small) {
      TweenMax.to(`#smallContainer${position}`, 0.5, {
        width: size,
        height: 40,
        marginTop: mobile ? size / 15 : size / 10,
        marginBottom: 0,
      });
      TweenMax.to(`#cubeFace1${position}`, 0.5, {
        width: size,
        height: 40,
      });
      TweenMax.to(`#cubeFace2${position}`, 0.5, {
        width: size,
        height: 40,
      });
      TweenMax.to(`#cubeFace3${position}`, 0.5, {
        width: size,
        height: 40,
      });
      TweenMax.to(`#cubeFace4${position}`, 0.5, {
        width: size,
        height: 40,
      });
      TweenMax.to(`#cubeFace5${position}`, 0.5, {
        width: size,
        height: size,
      });
    } else {
      TweenMax.to(`#smallContainer${position}`, 0.5, {
        width: size,
        height: size,
        marginTop: mobile ? size / 10 : size / 5,
        marginBottom: mobile ? size / 10 : size / 5,
      });
      TweenMax.to(`#cubeFace1${position}`, 0.5, {
        width: size,
        height: size,
      });
      TweenMax.to(`#cubeFace2${position}`, 0.5, {
        width: size,
        height: size,
      });
      TweenMax.to(`#cubeFace3${position}`, 0.5, {
        width: size,
        height: size,
      });
      TweenMax.to(`#cubeFace4${position}`, 0.5, {
        width: size,
        height: size,
      });
      TweenMax.to(`#cubeFace5${position}`, 0.5, {
        width: size,
        height: size,
      });
    }
  }, [small, size, mobile]);

  return (
    <SmallContainer
      id={`smallContainer${position}`}
      size={size}
      small={small}
      mobile={mobile}
    >
      <CubeContainer id="item" size={size} small={small} rotAngle={rotAngle}>
        <CubeFace1
          id={`cubeFace1${position}`}
          size={size}
          tiny={tiny}
          small={small}
          programs={programs}
          color={faceColor}
          onMouseEnter={small ? hoverInvert : undefined}
          onMouseLeave={hoverInvertOpposite}
        >
          <ProgramName
            small={small}
            mobile={mobile}
            color={faceColor}
            size={size}
            tiny={tiny}
          >
            {small ? programType : programs[0].name}
          </ProgramName>
          <Mask
            id={`cubeFace${position}Mask${small}`}
            small={small}
            top={false}
            onClick={
              !small
                ? () =>
                    changeBackgroundImg(
                      position,
                      0,
                      programs[0].name,
                      programs[0].desc,
                      programs[0].gatsbyRef
                    )
                : undefined
            }
          ></Mask>
        </CubeFace1>
        <CubeFace2
          id={`cubeFace2${position}`}
          size={size}
          tiny={tiny}
          small={small}
          programs={programs}
          color={faceColor}
          onMouseEnter={small ? hoverInvert : undefined}
          onMouseLeave={hoverInvertOpposite}
        >
          <ProgramName
            small={small}
            mobile={mobile}
            color={faceColor}
            size={size}
            tiny={tiny}
          >
            {small ? programType : programs[1].name}
          </ProgramName>
          <Mask
            id={`cubeFace${position}Mask${small}`}
            small={small}
            top={false}
            onClick={
              !small
                ? () =>
                    changeBackgroundImg(
                      position,
                      1,
                      programs[1].name,
                      programs[1].desc,
                      programs[1].gatsbyRef
                    )
                : undefined
            }
          ></Mask>
        </CubeFace2>
        <CubeFace3
          id={`cubeFace3${position}`}
          size={size}
          tiny={tiny}
          small={small}
          programs={programs}
          color={faceColor}
          onMouseEnter={small ? hoverInvert : undefined}
          onMouseLeave={hoverInvertOpposite}
        >
          <ProgramName
            small={small}
            mobile={mobile}
            color={faceColor}
            size={size}
            tiny={tiny}
          >
            {small ? programType : programs[2].name}
          </ProgramName>
          <Mask
            id={`cubeFace${position}Mask${small}`}
            small={small}
            top={false}
            onClick={
              !small
                ? () =>
                    changeBackgroundImg(
                      position,
                      2,
                      programs[2].name,
                      programs[2].desc,
                      programs[2].gatsbyRef
                    )
                : undefined
            }
          ></Mask>
        </CubeFace3>
        <CubeFace4
          id={`cubeFace4${position}`}
          size={size}
          tiny={tiny}
          small={small}
          programs={programs}
          color={faceColor}
          onMouseEnter={small ? hoverInvert : undefined}
          onMouseLeave={hoverInvertOpposite}
        >
          <ProgramName
            small={small}
            mobile={mobile}
            color={faceColor}
            size={size}
            tiny={tiny}
          >
            {small ? programType : programs[3].name}
          </ProgramName>
          <Mask
            id={`cubeFace${position}Mask${small}`}
            small={small}
            top={false}
            onClick={
              !small
                ? () =>
                    changeBackgroundImg(
                      position,
                      3,
                      programs[3].name,
                      programs[3].desc,
                      programs[3].gatsbyRef
                    )
                : undefined
            }
          ></Mask>
        </CubeFace4>
        <CubeFace5
          id={`cubeFace5${position}`}
          size={size}
          tiny={tiny}
          small={small}
          programs={programs}
          color={faceColor}
          onMouseEnter={small ? hoverInvert : undefined}
          onMouseLeave={hoverInvertOpposite}
        >
          <Mask
            small={small}
            top={true}
            id={`cubeFace${position}Mask${small}`}
          ></Mask>
        </CubeFace5>
      </CubeContainer>
    </SmallContainer>
  );
};

export default Cube;
