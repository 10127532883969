//Import statements
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Cube from "../components/cube";
import { TweenMax, TimelineMax, Power2, Power0, Power4 } from "gsap/TweenMax";
import { Draggable } from "gsap/Draggable";
import useWindowSize from "../hooks/useWindowSize";

import Right from "src/images/chevrons/right-chevron.png";
import Left from "src/images/chevrons/left-chevron.png";

import copy from "src/copy";
import Img from "gatsby-image";

const BgGatsbyImage = styled(Img)`
  height: 100vh;

  & > img {
    /* object-fit: cover !important;
    object-position: 0% 0% !important; */
  }
`;

const FullImageContainer = styled.div`
  background: black;
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  pointer-events: none;
`;

const FullImageButton = styled.div`
  background: black;
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 950;
  pointer-events: auto;
  display: ${props => (props.imageOpen ? `auto` : `none`)};
`;

const FullImageWrapper = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1100;
  pointer-events: none;
`;

const FullImageBack = styled.button`
  font-family: "URWAccidalia", sans-serif;
  font-size: 20px;
  background: none;
  margin: 0;
  color: white;
  border: none;
  margin-bottom: 20px;
  pointer-events: ${props => (props.imageOpen ? `auto` : `none`)};
  @media (max-width: 500px) {
    font-size: 14px;
  }
  transition: color 0.5s ease;
  &:hover {
    color: #757575;
  }
`;

const FullImageCarousel = styled.div`
  display: grid;
  grid-template-columns: 20% 60% 20%;
  width: 750px;
  z-index: 1100;
  @media (max-width: 700px) {
    width: 600px;
  }
  @media (max-height: 750px) and (min-width: 700px) {
    width: 650px;
  }
  @media (max-height: 600px) and (min-width: 700px) {
    width: 500px;
  }
  @media (max-width: 500px) {
    width: 400px;
    grid-template-columns: 10% 80% 10%;
  }
  @media (max-width: 380px) {
    width: 360px;
    grid-template-columns: 10% 80% 10%;
  }
  @media (max-width: 330px) {
    width: 300px;
    grid-template-columns: 10% 80% 10%;
  }
`;

const FullImage = styled(Img)`
  width: 500px;
  pointer-events: none;
  z-index: 400;
  justify-self: center;
  @media (max-width: 700px) {
    width: 400px;
  }
  @media (max-height: 750px) and (min-width: 700px) {
    width: 450px;
  }
  @media (max-height: 700px) and (min-width: 700px) {
    width: 400px;
  }
  @media (max-height: 600px) and (min-width: 700px) {
    width: 300px;
  }
  @media (max-width: 500px) {
    width: 305px;
  }
  @media (max-width: 380px) {
    width: 275px;
  }
  @media (max-width: 330px) {
    width: 225px;
  }
`;

const FullImageChevronWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
  &:hover {
    cursor: pointer;
  }
  pointer-events: ${props => (props.imageOpen ? `auto` : `none`)};
`;

const FullImageChevron = styled.img`
  width: 30px;
  z-index: 1100;
  pointer-events: auto;
  padding: 0;
  @media (max-width: 500px) {
    width: 24px;
  }
`;

const BGImage = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  opacity: 0;
  z-index: -500;
  transform: translateZ(-500px);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  object-fit: cover;
  display: ${props => (props.isOpen ? `block` : `none`)};
`;
const BGImage1 = styled(BGImage)`
  z-index: -501;
`;
const BGImage2 = styled(BGImage)`
  z-index: -502;
`;

const Overlay = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  background: black;
  opacity: ${props => `${props.opacity}`};
  z-index: -500;
  transition: opacity 0.5s ease-in-out;
  transform: translateZ(-400px);
  overflow-x: hidden;
  display: ${props => (props.isOpen ? `block` : `none`)};
`;

//Translate container position based on screen size
const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0px;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  transition: grid-gap 0.5s ease;
  @media (min-width: 1200px) {
    transform: translateY(-60px);
  }
  @media (max-width: 1200px) {
    transform: translateY(-80px);
  }
  @media (max-width: 800px) {
    grid-gap: ${props => (props.tiny ? "0" : "50px")};
    grid-template-columns: 100%;
    align-content: start;
    transform: translateY(0px);
  }
  @media (max-width: 800px) and (min-height: 550px) {
    transform: translateY(60px);
  }
  @media (max-width: 800px) and (min-height: 600px) {
    transform: translateY(100px);
  }
  @media (max-width: 800px) and (min-height: 900px) {
    transform: translateY(250px);
  }
`;

const PlaceholderDiv = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

//translate cubes placement based on screen size
const CubeWrapper = styled.div`
  z-index: 700;
  position: absolute;
  display: grid;
  grid-template-columns: 5% 25% 5%;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 100%;
  transition: padding-top 0.5s ease;
  @media (max-width: 1300px) {
    grid-template-columns: 5% 30% 5%;
  }
  @media (max-width: 1100px) {
    grid-template-columns: 5% 36% 5%;
  }
  @media (max-width: 800px) {
    padding-top: ${props => (props.tiny ? "45px" : "75px")};
    position: static;
    grid-template-columns: 5% 75% 5%;
  }
  @media (max-width: 500px) and (min-height: 600px) {
    padding-top: 75px;
  }
  @media (max-width: 400px) {
    grid-template-columns: 5% 80% 5%;
  }
  @media (min-width: 1600px) {
    grid-template-columns: 3% 22% 3%;
  }
  @media (min-width: 1800px) {
    grid-template-columns: 3% 20% 3%;
  }
  @media (min-width: 2000px) {
    grid-template-columns: 3% 18% 3%;
  }
  @media (min-width: 2500px) {
    grid-template-columns: 3% 15% 3%;
  }
  @media (max-width: 330px) {
    padding-top: ${props => (props.tiny ? "0px" : "75px")};
  }
`;

const StarterTextContainer = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 150px;
  z-index: 9000;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  visibility: 0;

  p {
    font-size: 16px;
    font-family: "Univers";
    color: white;
    text-align: left;
    text-align-last: left;
    z-index: 9000;
    width: 400px;
    line-height: 1.2;
    margin-top: 10px;
    @media (max-width: 500px) {
      width: 90%;
      font-size: 14px;
    }
  }
  @media (min-height: 500px) {
    bottom: 120px;
  }
  @media (min-height: 650px) {
    bottom: 130px;
  }
  @media (min-height: 700px) {
    bottom: 150px;
  }
  @media (min-height: 800px) {
    bottom: 200px;
  }
  @media (min-height: 1000px) {
    bottom: 300px;
  }
  @media (max-width: 350px) {
    bottom: 100px;
  }
  @media (max-width: 800px) and (min-height: 550px) {
    bottom: 150px;
  }
  @media (max-width: 800px) and (min-height: 600px) {
    bottom: 170px;
  }
`;

const StarterTextHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 400px;
  justify-self: center;
  align-self: center;

  h3 {
    font-size: 24px;
    font-family: "URWAccidalia";
    color: white;
    line-height: 1.2;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }

  @media (max-width: 500px) {
    width: 90%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  justify-self: start;
  align-self: flex-start;
  width: 75%;
  margin-left: 50px;
  position: relative;
  @media (max-width: 800px) {
    width: 90%;
  }
  @media (max-height: 575px) {
  }
  @media (max-width: 500px) {
    margin-left: 20px;
    width: 100%;
  }
`;

const TextHeader = styled.h3`
  color: white;
  font-size: 24px;
  font-family: "URWAccidalia";
  margin: 0;
  transition: opacity 0.2s ease;
  opacity: ${props => `${props.opacity}`};
  position: absolute;
  line-height: 1.2;
  @media (max-width: 1100px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 16px;
  }
  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

const TextDescriptionContainer = styled.div`
  position: absolute;
  width: 100%;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 700;
`;

const TextDescription = styled.p`
  color: white;
  width: 95%;
  text-align: left;
  font-size: 16px;
  font-family: "Univers";
  line-height: 1.2;
  @media (max-width: 800px) {
    width: 400px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
    width: 380px;
  }
  @media (max-width: 380px) {
    width: 340px;
  }
  @media (max-width: 370px) {
    width: 330px;
  }
  @media (max-width: 350px) {
    width: 285px;
    font-size: 10px;
  }
`;

const ImageGalleryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  display: ${props => (props.isClickedOn ? "auto" : "none")};
  margin-top: 20px;
`;

const ImageGalleryImage = styled.div`
  width: 30%;
  cursor: pointer;

  transition: filter 0.5s ease;

  &:hover {
    filter: brightness(50%);
  }
`;

const ImagesLink = styled.button`
  font-family: "URWAccidalia";
  color: white;
  font-size: 12px;
  border: none;
  background: none;
  text-align: left;
  padding: 0;
  margin: 15px 0 0 0;
  z-index: 700;
  display: ${props => (props.isClickedOn ? "block" : "none")};
  @media (max-width: 500px) {
    font-size: 12px;
    margin: 10px 0 0 0;
  }
  @media (max-width: 350px) {
    font-size: 10px;
  }
  transition: color 0.5s ease;
  &:hover {
    color: #757575;
  }
`;

const ChevronContainer = styled.div`
  transition: all 0.5s ease;
  margin-top: ${props => `${props.chevronPos}px`};
  z-index: 5;
`;

const Chevron = styled.img`
  width: 30px;
  transition: all 0.5s ease;
  opacity: ${props => `${props.chevronOpacity}`};
  padding: 30px 0 30px 0;
  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  perspective: 2000px;
  perspective-origin: 50% 30%;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  margin-left: 5px;
  width: 350px;
  opacity: 0;
  visibility: 0;
`;

const Cube3DContainer = styled.div`
  transform-style: preserve-3d;
  transform: rotateX(-10deg) rotateY(45deg);
  touch-action: manipulation;
`;

const Cube1 = styled.div`
  z-index: 20;
  transform-style: preserve-3d;
`;

const Cube2 = styled.div`
  z-index: 18;
  transform-style: preserve-3d;
`;

const Cube3 = styled.div`
  z-index: 16;
  transform-style: preserve-3d;
`;

const bgImages = [
  ...copy.programsOne,
  ...copy.programsTwo,
  ...copy.programsThree,
];

const CubeLayer = ({ data }) => {
  //Width of screen
  const { width, height } = useWindowSize();
  //States for cube layers
  const [isTouch, setIsTouch] = useState(false);
  //changing background
  const [background, setBackground] = useState(0);
  const [background1, setBackground1] = useState("nomoredreams");
  const [background2, setBackground2] = useState("nomoredreams");
  const [bgOnTop, setBgOnTop] = useState(1);
  const [opacity, setOpacity] = useState(1);
  //setting which cubes are open
  const [isOpen, setIsOpen] = useState(false);
  const [smallTop, setSmallTop] = useState(true);
  const [smallMiddle, setSmallMiddle] = useState(true);
  const [smallBottom, setSmallBottom] = useState(true);
  //cube size state
  const [cubeSize, setCubeSize] = useState(250);
  const [tinyCube, setTinyCube] = useState(false);
  //rotations of each cube
  const [rotationTop, setRotationTop] = useState(45);
  const [rotationMiddle, setRotationMiddle] = useState(45);
  const [rotationBottom, setRotationBottom] = useState(45);
  //setting description and headings
  const [isClickedOn, setIsClickedOn] = useState(false);
  const [description, setDescription] = useState("");
  const [heading, setHeading] = useState("nomoredreams");
  const [heading1, setHeading1] = useState("INTERNAL");
  const [heading2, setHeading2] = useState("HYBRID");
  const [heading3, setHeading3] = useState("EXTERNAL");
  const [heading1Opacity, setHeading1Opacity] = useState(0);
  const [heading2Opacity, setHeading2Opacity] = useState(0);
  const [heading3Opacity, setHeading3Opacity] = useState(0);
  //useRefs for cube layers
  //different timelines for each cube
  const tl1 = useRef(new TimelineMax({ repeat: -1 }));
  const tl2 = useRef(new TimelineMax({ repeat: -1 }));
  const tl3 = useRef(new TimelineMax({ repeat: -1 }));
  //which cube is open references
  const smallTopRef = useRef(smallTop);
  const smallMiddleRef = useRef(smallMiddle);
  const smallBottomRef = useRef(smallBottom);
  const isOpenRef = useRef(isOpen);
  //description and heading references
  const descriptionRef = useRef(description);
  const heading1Ref = useRef(heading1);
  const heading2Ref = useRef(heading2);
  const heading3Ref = useRef(heading3);
  const backgroundRef = useRef(background);
  const bgOnTopRef = useRef(bgOnTop);
  useEffect(() => {
    smallTopRef.current = smallTop;
    smallMiddleRef.current = smallMiddle;
    smallBottomRef.current = smallBottom;
    isOpenRef.current = isOpen;
    descriptionRef.current = description;
    heading1Ref.current = heading1;
    heading2Ref.current = heading2;
    heading3Ref.current = heading3;
    bgOnTopRef.current = bgOnTop;
    backgroundRef.current = background;
  });

  //Function to make description opacity 1
  const changeDescOpacity = () => {
    TweenMax.to("#description", 0.7, { autoAlpha: 1 });
  };

  //Functions to change background and text
  const changeBackgroundImg = (pos, face, name, desc, ref) => {
    setIsClickedOn(true);
    //change description based on hover
    setDescription(desc);
    setHeading(ref);
    //change headings based on which cube is open
    if (!smallTopRef.current) {
      setHeading1(name);
      setHeading2Opacity(0);
      setHeading3Opacity(0);
    } else if (!smallMiddleRef.current) {
      setHeading2(name);
      setHeading1Opacity(0);
      setHeading3Opacity(0);
    } else if (!smallBottomRef.current) {
      setHeading3(name);
      setHeading1Opacity(0);
      setHeading2Opacity(0);
    }
    //set background
    if (pos === 1) {
      setBackground(face);
    } else if (pos === 2) {
      setBackground(4 + face);
    } else if (pos === 3) {
      setBackground(8 + face);
    }
    setOpacity(0.7);
  };
  const changeBackgroundCol = num => {
    setIsClickedOn(false);
    //change background to black
    setOpacity(1);
    //change descriptions and headings based on which cube is open
    if (!smallTopRef.current) {
      setHeading1("INTERNAL");
      setHeading1Opacity(1);
      setHeading2("HYBRID");
      setHeading2Opacity(1);
      setHeading3("EXTERNAL");
      setHeading3Opacity(1);
    } else if (!smallMiddleRef.current) {
      setHeading1("INTERNAL");
      setHeading1Opacity(1);
      setHeading2("HYBRID");
      setHeading2Opacity(1);
      setHeading3("EXTERNAL");
      setHeading3Opacity(1);
    } else if (!smallBottomRef.current) {
      setHeading1("INTERNAL");
      setHeading1Opacity(1);
      setHeading2("HYBRID");
      setHeading2Opacity(1);
      setHeading3("EXTERNAL");
      setHeading3Opacity(1);
    }

    if (num === "1" || num === "2" || num === "3") {
      if (num === `1`) {
        setDescription(copy.programs[0].desc);
      } else if (num === `2`) {
        setDescription(copy.programs[1].desc);
      } else if (num === `3`) {
        setDescription(copy.programs[2].desc);
      }
    } else {
      if (!smallTopRef.current) {
        setDescription(copy.programs[0].desc);
      } else if (!smallMiddleRef.current) {
        setDescription(copy.programs[1].desc);
      } else if (!smallBottomRef.current) {
        setDescription(copy.programs[2].desc);
      }
    }
  };

  useEffect(() => {
    if (bgOnTop === 1) {
      setBackground2(bgImages[background].gatsbyRef);
    } else if (bgOnTop === 2) {
      setBackground1(bgImages[background].gatsbyRef);
    }
  }, [background]);

  //Function to expand top cube
  const changeTop = () => {
    //If first time opening cube, reposition cubes to make them larger
    TweenMax.to("#startText", 0.3, { autoAlpha: 0 });
    if (!isOpen) {
      if (width > 800) {
        TweenMax.to("#cubeWrapper", 0.5, { x: -250 });
      } else {
        TweenMax.set("#wrapper", { y: 0 });
      }
    }
    //if middle is currently large
    if (!smallMiddleRef.current) {
      //find closest angle for smooth animation
      const closestAngle = parseInt(
        90 *
          Math.round(
            document.getElementById("programsCube2")._gsTransform.rotationY / 90
          )
      );
      //animate to closest angle
      TweenMax.to("#programsCube2", 0.6, {
        ease: Power2.easeOut,
        rotationY: closestAngle,
        onComplete: () => {
          tl2.current.kill();
        },
      });
      //if bottom is currently large
    } else if (!smallBottomRef.current) {
      const closestAngle = parseInt(
        90 *
          Math.round(
            document.getElementById("programsCube3")._gsTransform.rotationY / 90
          )
      );
      TweenMax.to("#programsCube3", 0.6, {
        ease: Power2.easeOut,
        rotationY: closestAngle,
        onComplete: () => {
          tl3.current.kill();
        },
      });
    }
    //change states based on which cube is large
    setIsOpen(true);
    setSmallTop(false);
    setSmallMiddle(true);
    setSmallBottom(true);
    //animate open cube to spin constantly
    tl1.current.to("#programsCube1", 30, {
      rotationY: "+= 360",
      ease: Power0.easeNone,
    });
    setDescription(copy.programs[0].desc);
    //Reposition headings and descriptions based on screen size
    if (width > 800) {
      TweenMax.to("#description", 0.2, {
        autoAlpha: 0,
        top: -130,
        onComplete: changeDescOpacity,
      });
      TweenMax.to("#heading1", 0.05, { top: -170 });
      TweenMax.to("#heading1", 0.5, { autoAlpha: 1 });
      TweenMax.to("#heading2", 0.2, { autoAlpha: 0 });
      TweenMax.to("#heading3", 0.2, { autoAlpha: 0 });
    } else if (width < 500) {
      TweenMax.to("#container", 0.5, { y: 0 });
      TweenMax.to("#description", 0.2, {
        autoAlpha: 0,
        top: width < 350 ? -20 : height > 700 ? 70 : height > 600 ? 30 : 15,
        onComplete: changeDescOpacity,
      });
      TweenMax.to("#heading1", 0.2, {
        top: width < 350 ? -40 : height > 700 ? 40 : height > 600 ? 0 : -15,
        autoAlpha: 1,
      });
      TweenMax.to("#heading2", 0.2, { autoAlpha: 0 });
      TweenMax.to("#heading3", 0.2, { autoAlpha: 0 });
    } else {
      TweenMax.to("#container", 0.5, { y: 0 });
      TweenMax.to("#description", 0.2, {
        autoAlpha: 0,
        top: 40,
        onComplete: changeDescOpacity,
      });
      TweenMax.to("#heading1", 0.2, { top: 0, autoAlpha: 1 });
      TweenMax.to("#heading2", 0.2, { autoAlpha: 0 });
      TweenMax.to("#heading3", 0.2, { autoAlpha: 0 });
    }
  };

  //Function to expand middle cube
  const changeMiddle = () => {
    //If first time opening cube, reposition cubes to make them larger
    TweenMax.to("#startText", 0.3, { autoAlpha: 0 });
    if (!isOpen) {
      if (width > 800) {
        TweenMax.to("#cubeWrapper", 0.5, { x: -250 });
      } else {
        TweenMax.set("#wrapper", { y: 0 });
      }
    }
    //if top is currently large
    if (!smallTopRef.current) {
      //find closest angle for smooth animation
      const closestAngle = parseInt(
        90 *
          Math.round(
            document.getElementById("programsCube1")._gsTransform.rotationY / 90
          )
      );
      //animate to closest angle
      TweenMax.to("#programsCube1", 0.6, {
        ease: Power2.easeOut,
        rotationY: closestAngle,
        onComplete: () => {
          tl1.current.kill();
        },
      });
      //if bottom is currently large
    } else if (!smallBottomRef.current) {
      const closestAngle = parseInt(
        90 *
          Math.round(
            document.getElementById("programsCube3")._gsTransform.rotationY / 90
          )
      );
      TweenMax.to("#programsCube3", 0.6, {
        ease: Power2.easeOut,
        rotationY: closestAngle,
        onComplete: () => {
          tl3.current.kill();
        },
      });
    }
    //change states based on which cube is large
    setIsOpen(true);
    setSmallTop(true);
    setSmallMiddle(false);
    setSmallBottom(true);
    //animate open cube to spin constantly
    tl2.current.to("#programsCube2", 30, {
      rotationY: "+= 360",
      ease: Power0.easeNone,
    });
    setDescription(copy.programs[1].desc);
    //Reposition headings and descriptions based on screen size
    if (width > 800) {
      TweenMax.to("#description", 0.2, {
        autoAlpha: 0,
        top: -160,
        onComplete: changeDescOpacity,
      });
      TweenMax.to("#heading2", 0.05, { top: -200 });
      TweenMax.to("#heading2", 0.5, { autoAlpha: 1 });
      TweenMax.to("#heading1", 0.2, { autoAlpha: 0 });
      TweenMax.to("#heading3", 0.2, { autoAlpha: 0 });
    } else if (width < 500) {
      TweenMax.to("#container", 0.5, { y: 0 });
      TweenMax.to("#description", 0.2, {
        autoAlpha: 0,
        top: width < 350 ? -20 : height > 700 ? 70 : height > 600 ? 30 : 15,
        onComplete: changeDescOpacity,
      });
      TweenMax.to("#heading1", 0.2, { autoAlpha: 0 });
      TweenMax.to("#heading2", 0.2, {
        top: width < 350 ? -40 : height > 700 ? 40 : height > 600 ? 0 : -15,
        autoAlpha: 1,
      });
      TweenMax.to("#heading3", 0.2, { autoAlpha: 0 });
    } else {
      TweenMax.to("#container", 0.5, { y: 0 });
      TweenMax.to("#description", 0.2, {
        autoAlpha: 0,
        top: 40,
        onComplete: changeDescOpacity,
      });
      TweenMax.to("#heading1", 0.2, { autoAlpha: 0 });
      TweenMax.to("#heading2", 0.2, { top: 0, autoAlpha: 1 });
      TweenMax.to("#heading3", 0.2, { autoAlpha: 0 });
    }
  };

  //Function to expand bottom cube
  const changeBottom = () => {
    TweenMax.to("#startText", 0.3, { autoAlpha: 0 });
    if (!isOpen) {
      if (width > 800) {
        TweenMax.to("#cubeWrapper", 0.5, { x: -250 });
      } else {
        TweenMax.set("#wrapper", { y: 0 });
      }
    }
    //if middle is currently large
    if (!smallMiddleRef.current) {
      //find closest angle for smooth animation
      const closestAngle = parseInt(
        90 *
          Math.round(
            document.getElementById("programsCube2")._gsTransform.rotationY / 90
          )
      );
      //animate to closest angle
      TweenMax.to("#programsCube2", 0.6, {
        ease: Power2.easeOut,
        rotationY: closestAngle,
        onComplete: () => {
          tl2.current.kill();
        },
      });
      //if top is currently large
    } else if (!smallTopRef.current) {
      const closestAngle = parseInt(
        90 *
          Math.round(
            document.getElementById("programsCube1")._gsTransform.rotationY / 90
          )
      );
      TweenMax.to("#programsCube1", 0.6, {
        ease: Power2.easeOut,
        rotationY: closestAngle,
        onComplete: () => {
          tl1.current.kill();
        },
      });
    }
    //change states based on which cube is large
    setIsOpen(true);
    setSmallTop(true);
    setSmallMiddle(true);
    setSmallBottom(false);
    //animate open cube to spin constantly
    tl3.current.to("#programsCube3", 30, {
      rotationY: "+= 360",
      ease: Power0.easeNone,
    });
    setDescription(copy.programs[2].desc);
    //Reposition headings and descriptions based on screen size
    if (width > 800) {
      TweenMax.to("#description", 0.2, {
        autoAlpha: 0,
        top: -175,
        onComplete: changeDescOpacity,
      });
      TweenMax.to("#heading3", 0.05, { top: -215 });
      TweenMax.to("#heading3", 0.5, { autoAlpha: 1 });
      TweenMax.to("#heading2", 0.2, { autoAlpha: 0 });
      TweenMax.to("#heading1", 0.2, { autoAlpha: 0 });
    } else if (width < 500) {
      TweenMax.to("#container", 0.5, { y: 0 });
      TweenMax.to("#description", 0.2, {
        autoAlpha: 0,
        top: width < 350 ? -20 : height > 700 ? 70 : height > 600 ? 30 : 5,
        onComplete: changeDescOpacity,
      });
      TweenMax.to("#heading1", 0.2, { autoAlpha: 0 });
      TweenMax.to("#heading2", 0.2, { autoAlpha: 0 });
      TweenMax.to("#heading3", 0.2, {
        top: width < 350 ? -40 : height > 700 ? 40 : height > 600 ? 0 : -25,
        autoAlpha: 1,
      });
    } else {
      TweenMax.to("#container", 0.5, { y: 0 });
      TweenMax.to("#description", 0.2, {
        autoAlpha: 0,
        top: 40,
        onComplete: changeDescOpacity,
      });
      TweenMax.to("#heading1", 0.2, { autoAlpha: 0 });
      TweenMax.to("#heading2", 0.2, { autoAlpha: 0 });
      TweenMax.to("#heading3", 0.2, { top: 0, autoAlpha: 1 });
    }
  };

  //Function to rotate any cube left
  const rotateLeft = () => {
    //if top cube is open
    if (!smallTopRef.current) {
      //rotate cubes left and then restart timeline
      TweenMax.to("#programsCube1", 1, {
        ease: Power2.easeOut,
        rotationY: "-= 90",
        onComplete: () => {
          tl1.current.clear();
          tl1.current.to("#programsCube1", 30, {
            rotationY: "-= 360",
            ease: Power0.easeNone,
          });
        },
      });
      //if middle cube is open
    } else if (!smallMiddleRef.current) {
      TweenMax.to("#programsCube2", 1, {
        ease: Power2.easeOut,
        rotationY: "-= 90",
        onComplete: () => {
          tl2.current.clear();
          tl2.current.to("#programsCube2", 30, {
            rotationY: "-= 360",
            ease: Power0.easeNone,
          });
        },
      });
      //if bottom cube is open
    } else if (!smallBottomRef.current) {
      TweenMax.to("#programsCube3", 1, {
        ease: Power2.easeOut,
        rotationY: "-= 90",
        onComplete: () => {
          tl3.current.clear();
          tl3.current.to("#programsCube3", 30, {
            rotationY: "-= 360",
            ease: Power0.easeNone,
          });
        },
      });
    }
  };

  //Function to rotate any cube right
  const rotateRight = () => {
    //if small cube is open
    if (!smallTopRef.current) {
      //rotate cubes right and then restart timeline
      TweenMax.to("#programsCube1", 1, {
        ease: Power2.easeOut,
        rotationY: "+= 90",
        onComplete: () => {
          tl1.current.clear();
          tl1.current.to("#programsCube1", 30, {
            rotationY: "+= 360",
            ease: Power0.easeNone,
          });
        },
      });
      //if middle cube is open
    } else if (!smallMiddleRef.current) {
      TweenMax.to("#programsCube2", 1, {
        ease: Power2.easeOut,
        rotationY: "+= 90",
        onComplete: () => {
          tl2.current.clear();
          tl2.current.to("#programsCube2", 30, {
            rotationY: "+= 360",
            ease: Power0.easeNone,
          });
        },
      });
      //if bottom cube is open
    } else if (!smallBottomRef.current) {
      TweenMax.to("#programsCube3", 1, {
        ease: Power2.easeOut,
        rotationY: "+= 90",
        onComplete: () => {
          tl3.current.clear();
          tl3.current.to("#programsCube3", 30, {
            rotationY: "+= 360",
            ease: Power0.easeNone,
          });
        },
      });
    }
  };

  //Adjust chevron position based on cubes
  let chevronPos = 25;
  let chevronOpacity = 0;
  if (!smallTop) {
    if (width > 400) {
      chevronPos = -60;
    } else {
      chevronPos = -90;
    }
    chevronOpacity = 1;
  } else if (!smallMiddle) {
    if (width > 400) {
      chevronPos = 60;
    } else {
      chevronPos = 40;
    }
    chevronOpacity = 1;
  } else if (!smallBottom) {
    if (width > 400) {
      chevronPos = 170;
    } else {
      chevronPos = 150;
    }
    chevronOpacity = 1;
  }

  //check if touchscreen
  useEffect(() => {
    if (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    ) {
      setIsTouch(true);
    } else {
      setIsTouch(false);
    }
  }, []);

  //UseEffect to set GSAP draggable feature on cubes
  useEffect(() => {
    for (let num = 1; num < 4; num++) {
      Draggable.create(`#programsCube${num}`, {
        allowEventDefault: true,
        bounds: {
          minX: 0,
          maxX: 0,
          minY: 0,
          maxY: 0,
        },
        onPress() {
          this.prev = this.pointerX;
        },
        onRelease() {
          if (num === 1 && !smallTopRef.current) {
            if (this.prev - this.pointerX > 5) {
              rotateLeft();
            } else if (this.pointerX - this.prev > 5) {
              rotateRight();
            }
          }
          if (num === 2 && !smallMiddleRef.current) {
            if (this.prev - this.pointerX > 5) {
              rotateLeft();
            } else if (this.pointerX - this.prev > 5) {
              rotateRight();
            }
          }
          if (num === 3 && !smallBottomRef.current) {
            if (this.prev - this.pointerX > 5) {
              rotateLeft();
            } else if (this.pointerX - this.prev > 5) {
              rotateRight();
            }
          }
        },
      });
    }
    //start cubes lower before opening them
    if (width < 800) {
      if (height > 600) {
        TweenMax.set("#wrapper", { y: 100 });
      } else {
        TweenMax.set("#wrapper", { y: 20 });
      }
    }
  }, []);

  //change cube size based on screen size
  let mobile = false;
  if (width < 500) {
    mobile = true;
  }
  useEffect(() => {
    if (width > 415) {
      if (!isOpen) {
        setCubeSize(250);
      } else {
        TweenMax.to("#cubeWrapper", 0.5, { scale: 1 });
      }
    } else if (width > 400) {
      if (!isOpen) {
        setCubeSize(180);
      } else {
        setTinyCube(true);
        TweenMax.to("#cubeWrapper", 0.5, { scale: 0.8 });
      }
    } else if (width > 350) {
      if (!isOpen) {
        setCubeSize(160);
      } else {
        setTinyCube(true);
        if (height > 700) {
          TweenMax.to("#cubeWrapper", 0.5, { scale: 0.9 });
        } else {
          TweenMax.to("#cubeWrapper", 0.5, { scale: 0.7 });
        }
      }
    } else {
      if (!isOpen) {
        setCubeSize(150);
      } else {
        setTinyCube(true);
        TweenMax.to("#cubeWrapper", 0.5, { scale: 0.5 });
      }
    }
  }, [width, isOpen, height]);

  useEffect(() => {
    TweenMax.killTweensOf(`#bg1`);
    TweenMax.killTweensOf(`#bg2`);
    if (bgOnTopRef.current === 1) {
      TweenMax.to(`#bg1`, 0.5, {
        autoAlpha: 0,
      });
      TweenMax.to(`#bg2`, 0.5, {
        autoAlpha: 1,
      });
      setBgOnTop(2);
    } else if (bgOnTopRef.current === 2) {
      TweenMax.to(`#bg2`, 0.5, {
        autoAlpha: 0,
      });
      setBgOnTop(1);
      TweenMax.to(`#bg1`, 0.5, {
        autoAlpha: 1,
      });
    }
  }, [background]);

  useEffect(() => {
    TweenMax.to("#programCubeWrapper", 1.25, {
      autoAlpha: 1,
      ease: Power4.easeInOut,
    });
    TweenMax.to("#startText", 1.25, { autoAlpha: 1, ease: Power4.easeInOut });
    TweenMax.set("#programsCube1", { y: -500 });
    TweenMax.set("#programsCube2", { y: -500 });
    TweenMax.set("#programsCube3", { y: -500 });
    TweenMax.to("#programsCube1", 1.5, { y: 0 });
    TweenMax.to("#programsCube2", 1.25, { y: 0 });
    TweenMax.to("#programsCube3", 1, { y: 0 });
  }, []);

  const [imageSrc, setImageSrc] = useState("");
  const [imageOpen, setImageOpen] = useState(false);
  const [fullImage, setFullImage] = useState(0);

  const openImage = (src, index) => {
    setImageOpen(true);
    if (src) {
      setFullImage(index);
      setImageSrc(src);
    } else {
      setFullImage(0);
      setImageSrc(data[`gallery${heading}`].edges[0].node.large.fluid);
    }
    TweenMax.to("#fullImageContainerPrograms", 0.5, { autoAlpha: 1 });
  };

  const hideImg = () => {
    setImageOpen(false);
    TweenMax.to("#fullImageContainerPrograms", 0.5, { autoAlpha: 0 });
  };
  const leftImage = () => {
    const numberOfElement = data[`gallery${heading}`].edges.length - 1;

    if (fullImage !== 0) {
      setFullImage(prev => prev - 1);
      setImageSrc(
        data[`gallery${heading}`].edges[fullImage - 1].node.large.fluid
      );
    } else {
      setFullImage(numberOfElement);
      setImageSrc(
        data[`gallery${heading}`].edges[numberOfElement].node.large.fluid
      );
    }
  };

  const rightImage = () => {
    const numberOfElement = data[`gallery${heading}`].edges.length - 1;

    if (fullImage !== numberOfElement) {
      setFullImage(prev => prev + 1);
      setImageSrc(
        data[`gallery${heading}`].edges[fullImage + 1].node.large.fluid
      );
    } else {
      setFullImage(0);
      setImageSrc(data[`gallery${heading}`].edges[0].node.large.fluid);
    }
  };

  //Return components and HTML
  return (
    <>
      <FullImageButton
        imageOpen={imageOpen}
        onClick={hideImg}
        id="programsFullImageButton"
      ></FullImageButton>
      <FullImageContainer id="fullImageContainerPrograms">
        <FullImageWrapper>
          <FullImageBack onClick={hideImg} imageOpen={imageOpen}>
            BACK
          </FullImageBack>
          <FullImageCarousel>
            <FullImageChevronWrapper imageOpen={imageOpen} onClick={leftImage}>
              <FullImageChevron src={Left}></FullImageChevron>
            </FullImageChevronWrapper>
            <FullImage
              className="tweenImg"
              fluid={imageSrc || data.challenges.childImageSharp.fluid}
            />
            <FullImageChevronWrapper imageOpen={imageOpen} onClick={rightImage}>
              <FullImageChevron src={Right}></FullImageChevron>
            </FullImageChevronWrapper>
          </FullImageCarousel>
        </FullImageWrapper>
      </FullImageContainer>

      <BGImage1 id="bg1" isOpen={isOpen}>
        <BgGatsbyImage fluid={data[background1].childImageSharp.fluid} />
      </BGImage1>
      <BGImage2 id="bg2" isOpen={isOpen}>
        <BgGatsbyImage fluid={data[background2].childImageSharp.fluid} />
      </BGImage2>
      <Overlay opacity={opacity} isOpen={isOpen}></Overlay>

      <StarterTextContainer id="startText">
        <StarterTextHeader>
          <h3>OVERVIEW</h3>
          <h3>&ndash;</h3>
        </StarterTextHeader>
        <p>
          HXOUSE runs three types of programming: internal, hybrid, and
          external, all consisting of different events and programs.
        </p>
      </StarterTextContainer>

      <Container
        id="container"
        width={width}
        tiny={tinyCube}
        onClick={e => {
          if (
            e.target.getAttribute("id") !== "cubeFace1Maskfalse" &&
            e.target.getAttribute("id") !== "cubeFace2Maskfalse" &&
            e.target.getAttribute("id") !== "cubeFace3Maskfalse" &&
            e.target.getAttribute("id") !== "imagesLink" &&
            e.target.getAttribute("loading") !== "lazy"
          ) {
            if (
              e.target.getAttribute("id") === "cubeFace1Masktrue" ||
              e.target.getAttribute("id") === "cubeFace2Masktrue" ||
              e.target.getAttribute("id") === "cubeFace3Masktrue"
            ) {
              changeBackgroundCol(e.target.getAttribute("id").charAt(8));
            } else {
              changeBackgroundCol("0");
            }
          }
        }}
      >
        <CubeWrapper id="cubeWrapper" tiny={tinyCube}>
          <ChevronContainer chevronPos={chevronPos} id="chevronContainer1">
            <Chevron
              onClick={rotateLeft}
              chevronOpacity={chevronOpacity}
              src={Left}
            />
          </ChevronContainer>

          <Wrapper width={width} id="programCubeWrapper">
            <Cube3DContainer>
              <Cube1
                onClick={smallTop ? changeTop : undefined}
                id="programsCube1"
              >
                <Cube
                  size={cubeSize}
                  mobile={mobile}
                  isTouch={isTouch}
                  small={smallTop}
                  rotAngle={rotationTop}
                  position={1}
                  programs={copy.programsOne}
                  changeBackgroundCol={changeBackgroundCol}
                  changeBackgroundImg={changeBackgroundImg}
                  programType="INTERNAL"
                  id="item1"
                />
              </Cube1>
              <Cube2
                onClick={smallMiddle ? changeMiddle : undefined}
                id="programsCube2"
              >
                <Cube
                  size={cubeSize}
                  mobile={mobile}
                  isTouch={isTouch}
                  small={smallMiddle}
                  rotAngle={rotationMiddle}
                  position={2}
                  programs={copy.programsTwo}
                  changeBackgroundCol={changeBackgroundCol}
                  changeBackgroundImg={changeBackgroundImg}
                  programType="HYBRID"
                  id="item2"
                />
              </Cube2>
              <Cube3
                onClick={smallBottom ? changeBottom : undefined}
                id="programsCube3"
              >
                <Cube
                  size={cubeSize}
                  mobile={mobile}
                  isTouch={isTouch}
                  small={smallBottom}
                  rotAngle={rotationBottom}
                  position={3}
                  programs={copy.programsThree}
                  changeBackgroundCol={changeBackgroundCol}
                  changeBackgroundImg={changeBackgroundImg}
                  programType="EXTERNAL"
                  id="item3"
                />
              </Cube3>
            </Cube3DContainer>
          </Wrapper>

          <ChevronContainer chevronPos={chevronPos} id="chevronContainer2">
            <Chevron
              onClick={rotateRight}
              chevronOpacity={chevronOpacity}
              src={Right}
            />
          </ChevronContainer>
        </CubeWrapper>
        <PlaceholderDiv></PlaceholderDiv>
        <TextContainer id="textContainer" mobile={mobile}>
          <TextHeader
            id="heading1"
            onClick={smallTop ? changeTop : undefined}
            opacity={heading1Opacity}
            mobile={mobile}
          >
            {heading1}
          </TextHeader>
          <TextHeader
            id="heading2"
            onClick={smallMiddle ? changeMiddle : undefined}
            opacity={heading2Opacity}
            mobile={mobile}
          >
            {heading2}
          </TextHeader>
          <TextHeader
            id="heading3"
            onClick={smallBottom ? changeBottom : undefined}
            opacity={heading3Opacity}
            mobile={mobile}
          >
            {heading3}
          </TextHeader>
          <TextDescriptionContainer id="description">
            <TextDescription mobile={mobile} width={width}>
              {description}
            </TextDescription>

            {width < 800 ? (
              <ImagesLink
                isClickedOn={isClickedOn}
                onClick={() => openImage()}
                id="imagesLink"
              >
                VIEW GALLERY
              </ImagesLink>
            ) : (
              <ImageGalleryContainer id="imagesLink" isClickedOn={isClickedOn}>
                {data[`gallery${heading}`].edges.slice(0, 3).map((img, i) => (
                  <ImageGalleryImage
                    onClick={() => openImage(img.node.large.fluid, i)}
                    key={i}
                  >
                    <Img
                      className="programsGalleryImage"
                      fluid={img.node.small.fluid}
                    />
                  </ImageGalleryImage>
                ))}
              </ImageGalleryContainer>
            )}
          </TextDescriptionContainer>
        </TextContainer>
      </Container>
    </>
  );
};

export default CubeLayer;
